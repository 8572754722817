<template>
  <div class="px-8 mt-4">
    <v-dialog
      v-model="dialogModel"
      persistent
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="poModal"
      scrollable
    >
      <v-card>
        <v-toolbar dark color="green darken-2" max-height="65">
          <v-toolbar-title> {{ risData.RISCODE }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div>
          <v-stepper alt-labels>
            <v-stepper-header>
              <v-stepper-step
                color="green"
                step="1"
                class="pa-3 pl-md-16"
                :complete="risData.RequestedStatusID == 7"
                :rules="[() => risData.RequestedStatusID != 6]"
              >
              <span class="text-no-wrap">Requisition Approval</span>
              <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(risData.RequestedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="risData.ApprovedStatusID == 7"
                :rules="[() => risData.ApprovedStatusID != 6]"
                step="2"
                class="pa-3"
              >
                <span class="text-no-wrap">Director of Administrative Services Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(risData.ApprovedDate)}}</small>
              </v-stepper-step>

              <v-divider class="my-6"></v-divider>

              <v-stepper-step
                color="green"
                :complete="risData.IssuedStatusID == 7"
                :rules="[() => risData.IssuedStatusID != 6]"
                step="3"
                class="pa-3 pr-md-16"
              >
                <span class="text-no-wrap">Issuance Approval</span>
                <small class="text-no-wrap font-size-12 font-weight-bold">{{formatDateTime(risData.IssuedDate)}}</small>
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </div>
        <v-alert
          border="left"
          type="error"
          v-if="risData.RequestedStatusID == 6 || risData.ApprovedStatusID == 6 || risData.IssuedStatusID == 6"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <div v-if="risData.RequestedRemarks"> <strong>Requestor:</strong> {{ risData.RequestedRemarks }}</div>
          <div v-if="risData.ApprovedRemarks"> <strong>Director of Administrative Services:</strong> {{ risData.ApprovedRemarks }}</div>
          <div v-if="risData.IssuedRemarks"> <strong>Issuer:</strong> {{ risData.IssuedRemarks }}</div>
        </v-alert>
        <v-alert
          border="left"
          type="error"
          v-if="risData.IsCancelled"
          prominent
          dense
          class="white--text amber accent-4"
        >
          <strong>CANCELLED</strong><br />
          <small>Date Cancelled: {{formatDateTime(risData.DateCancelled)}}</small>
        </v-alert>
        <v-card-text style="max-height: 900px;">
          <div>
            <v-row class="mt-4">
              <v-col cols="12" class="d-flex justify-center">
                <v-subheader class="font-weight-bold text-h6"
                  >REQUISITION AND ISSUANCE SLIP</v-subheader
                >
              </v-col>
            </v-row>

            <v-row no-gutters class="mt-5">
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Entity Name:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>Davao del Norte State College</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Fund Cluster:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.FundCode }} - {{ risData.SpecificFundSourceDescription }}</v-subheader>
            </v-col>
          </v-row>

           <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Supplier:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.CompanyName }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">IAR No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.IARCODE }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">PO No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.POCode }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Date Created:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ formatDate(risData.DateCreated) }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">RIS No.:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.RISCODE }}</v-subheader>
            </v-col>

            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">Receiver:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.receiver }}</v-subheader>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" sm="6" md="3">
              <v-subheader class="font-weight-bold">RIS Purpose:</v-subheader>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-subheader>{{ risData.RISPurpose }}</v-subheader>
            </v-col>
          </v-row>
          </div>
          <v-row>
            <v-col lg="12" class="">
              <v-data-table
                v-model="formData.Lists"
                :headers="headers"
                :items="itemList"
                item-key="RISItemID"
                class="elevation-1 mt-4"
                disable-pagination
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <span class="green--text text--darken-2"
                      >List Of Items</span
                    >
                    <v-spacer></v-spacer>
                    <span v-if="risData.IsCancelled == 0">
                      <!-- v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 16) && risData.IssuedStatusID != 7 && (parseFloat(risData.ICSQty) + parseFloat(risData.PARQty)) <= 0" -->
                    <v-btn
                        color="header darken-2"
                        dark
                        outlined
                        elevation="2"
                        small
                        class="mr-2"
                        @click="approveRIS(risData)"
                        v-if="status == 0 || status == 1"
                      >
                        Approve
                      </v-btn>
                      <v-btn
                        color="red darken-2"
                        dark
                        outlined
                        elevation="2"
                        small
                        class="mr-2"
                        @click="pendingRIS(risData)"
                        v-if="status == 0"
                      >
                        Pending
                      </v-btn>
                    <v-btn
                      outlined
                      color="grey darken-2 mr-2"
                      dark
                      small
                      @click="edit()"
                      class="mr-2"
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 16) && risData.IssuedStatusID != 7"
                    >
                      <v-icon>mdi-pencil-outline</v-icon>
                      Edit RIS
                    </v-btn>
                    <v-btn
                      outlined
                      color="red darken-2"
                      dark
                      small
                      @click="print()"
                      class="mr-2"
                    >
                      <v-icon>print</v-icon>
                      Print
                    </v-btn>
                    <v-btn
                      color="red darken-2"
                      class="white--text"
                      small
                      v-if="($store.state.user.roleID == 6 || $store.state.user.roleID == 16) && itemList.length > 0 && (parseFloat(risData.ICSQty) + parseFloat(risData.PARQty)) <= 0"
                      @click="unpublish()"
                    >
                      Unpublish
                    </v-btn>
                    </span>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.UnitCost`]="{ item }">
                  <span class="text-no-wrap">₱ {{ formatPrice(item.UnitCost) }}</span>
                </template>
                <template v-slot:[`item.OverallQty`]="{ item }">
                  <span class="text-no-wrap">{{ formatQty(item.OverallQty) }}</span>
                </template>
                <template v-slot:[`item.Amount`]="{ item }">
                  <span class="text-no-wrap">₱ {{formatPrice(parseFloat(item.UnitCost) * parseFloat(item.OverallQty))}}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUnpublish" persistent max-width="450px">
      <v-card>
        <v-card-title>
          <span class="subheader font-weight-bold">Confirmation</span>
          <v-spacer></v-spacer>
          <v-btn icon dark color="secondary2" @click="dialogUnpublish = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
          <v-card-text>
            Are you sure you want to unpublish this RIS?
          </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" outlined @click="dialogUnpublish = false">
          Cancel
          </v-btn>
          <v-btn
            color="red darken-3"
            :loading="isLoading"
            class="white--text"
            @click="confirmUnpublish()"
          >
          <v-icon>delete</v-icon>
            Unpublish
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRISType" max-width="500px">
      <v-card>
        <v-card-title class="text-h6">Please select RIS type:</v-card-title>
        <v-card-text>
          <v-row justify="center" class="mt-2">
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple</v-icon
              ><br />
              <v-btn color="green darken-2" @click="supply()" text
                >Supply</v-btn
              >
            </v-col>
            <v-col align="center" cols="6" xs="12">
              <v-icon dense x-large color="green darken-2"
                >mdi-file-document-multiple-outline</v-icon
              ><br />
              <v-btn color="green darken-2" @click="accounting()" text
                >Accounting</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-2" text @click="dialogRISType = false"
                >Cancel</v-btn
              >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="approveDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to approve this Requisition and Issue Slip?
            <br /><br />
            <div>RIS #: <strong>{{approveData.RISCODE}}</strong></div>
            <div>Purpose: <strong>{{approveData.RISPurpose}}</strong></div>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="approveDialog = false">Cancel</v-btn>
            <v-btn color="header darken-2 white--text" @click="confirmApproveRIS()"><v-icon class="mr-1">mdi-check-circle</v-icon>Approve</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-dialog v-model="pendingDialog" max-width="550px">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <div class="mx-6">
            Are you sure you want to pending this Requisition and Issue Slip?
            <br /><br />
            <div>RIS #: <strong>{{pendingData.RISCODE}}</strong></div>
            <div>Purpose: <strong>{{pendingData.RISPurpose}}</strong></div>
            <br />
            <v-form ref="formRef">
            <v-textarea
              color="green"
                dense
                v-model="formData.Remarks"
                :rules="[formRules.required]"
                label="Remarks"
                counter
                outlined
                maxlength="500"
                ></v-textarea>
            </v-form>
          </div>
          <v-card-actions class="mt-5">
            <v-spacer></v-spacer>
            <v-btn color="grey darken-2" outlined @click="pendingDialog = false">Cancel</v-btn>
            <v-btn color="red darken-2 white--text" @click="confirmPendingRIS()"><v-icon class="mr-1">mdi-clock-outline</v-icon>Pending</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <EditRISModal :risData="editData" />
    <fade-away-message-component
      displayType="variation2"
      v-model="fadeAwayMessage.show"
      :message="fadeAwayMessage.message"
      :header="fadeAwayMessage.header"
      :top="fadeAwayMessage.top"
      :type="fadeAwayMessage.type"
    ></fade-away-message-component>
  </div>
</template>
    
    <script>
export default {
  props: {
    risData: {},
  },
  components: {
    EditRISModal: () => import("./CreateRIS.vue"),
  },
  data: () => ({
    dialog: false,
    users: [],
    dialogModel: false,
    PurposeDialog: false,
    supplier: [],
    formData: {
      Lists: [],
      ICSPurpose: '',
      PARPurpose: '',
      RISPurpose: '',
    },
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,
    menu6: false,
    itemList: [],
    link: "",
    fadeAwayMessage: {
      show: false,
      type: "success",
      header: "Successfully Added!",
      message: "",
      top: 10,
    },
    headers: [
      {
        text: "Stock/ Property No.",
        align: "start",
        sortable: false,
        value: "StockPropertyNo",
      },
      {
        text: "Item Name",
        align: "start",
        sortable: false,
        value: "ItemName",
      },
      { text: "Description", value: "ItemDescription", sortable: false },
      {
        text: "Unit Measure",
        value: "UnitMeasureDescription",
        sortable: false,
      },
      { text: "Unit Cost", align: "end", value: "UnitCost", sortable: false },
      { text: "QTY", align: "center", value: "OverallQty", sortable: false },
      { text: "Amount", align: "end", value: "Amount", sortable: false },
    ],
    iprType: '',
    IPRDialog: false,
    editData: [],
    dialogUnpublish: false,
    isLoading: false,
    dialogRISType: false,
    risType: 1,
    status: null,
    approveDialog: false,
    pendingDialog: false,
    approveData: [],
    pendingData: [],
  }),
  watch: {
    risData: {
      handler(data) {
        if (data.RISID) {
          if(data.status_prop != null && typeof data.status_prop !== 'undefined'){
            this.status = data.status_prop;
          }
          this.getItems();
          this.dialogModel = true;
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.eventHub.$on("closeCreateRIS", () => {
      this.getItems();
    });
  },
  beforeDestroy() {
    this.eventHub.$off("closeCreateRIS");
  },
  methods: {
    print() {
      this.dialogRISType = true;
    },
    supply(){
      this.link = this.apiUrl + "/ris/report/" + this.risData.RISID + "/1";
      window.open(this.link);
    },
    accounting(){
      this.link = this.apiUrl + "/ris/report/" + this.risData.RISID + "/2";
      window.open(this.link);
    },
    edit(){
      this.editData = {...this.risData, edit: true, items: this.itemList};
    },
    unpublish(){
      this.dialogUnpublish = true;
    },
    closeDialog() {
      this.formData.Lists = [];
      this.itemList = [];
      this.dialogRISType = false;
      this.dialogModel = false;
      this.eventHub.$emit("closeViewRIS", false);
    },

    getItems() {
      this.formData.Lists = [];
      let data = new FormData();
      data.append("RISID", this.risData.RISID);
      this.axiosCall("/ris/getItems", "POST", data).then((res) => {
        this.itemList = res.data.data.result;
        this.users = res.data.data.users;
      });
    },
    confirmUnpublish(){
      let data = new FormData();
      data.append("RISID", this.risData.RISID);
      this.axiosCall("/ris/unpublish", "POST", data).then((res) => {
        if(res.data.status){
        this.closeDialog();
          this.dialogUnpublish = false;
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "success";
          this.fadeAwayMessage.message = "RIS was successfully unpublished";
          this.fadeAwayMessage.header = "System Message";
        }
        else{
          this.fadeAwayMessage.show = true;
          this.fadeAwayMessage.type = "error";
          this.fadeAwayMessage.message = "Something went wrong!";
          this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    approveRIS(data){
      this.approveData = data;
      this.approveDialog = true;
    },
    pendingRIS(data){
      this.pendingData = data;
      this.pendingDialog = true;
    },
    confirmApproveRIS(){
      let data = new FormData();
      data.append("RISID", this.approveData.RISID);
      data.append("Admin", 1);
      this.axiosCall("/ris/approve", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RIS was successfully approved.";
            this.fadeAwayMessage.header = "System Message";
            this.approveDialog = false;
            this.approveData = [];
            this.status = null;
            this.closeDialog()
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
    },
    confirmPendingRIS(){
      if(this.$refs.formRef.validate()){
      let data = new FormData();
      data.append("RISID", this.pendingData.RISID);
      data.append("Remarks", this.formData.Remarks);
      data.append("Admin", 1);
      this.axiosCall("/ris/pending", "POST", data).then((res) => {
        if(res.data.status){
            this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "success";
            this.fadeAwayMessage.message = "RIS was successfully in pending status.";
            this.fadeAwayMessage.header = "System Message";
            this.pendingDialog = false;
            this.pendingData = [];
            this.status = 1;
            this.closeDialog()
        }
        else{
          this.fadeAwayMessage.show = true;
            this.fadeAwayMessage.type = "error";
            this.fadeAwayMessage.message = "Something went wrong!";
            this.fadeAwayMessage.header = "System Message";
        }
      });
      }
    },
  },
};
</script>
        
    <style scoped>
.v-dialog--fullscreen,
.poModal {
  overflow-x: hidden !important;
}
</style>